function calculateDeliveryPeriod(batchId, product, deliveryMethod, order) {
  if (deliveryMethod === "delivery") {
    let date;
    if (order && order.paid) {
      date = new Date(order.updated_at);
    } else {
      date = new Date();
    }

    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };
    const expirationPlus2BusinessDays = businessDaysToAdd(date, 2);
    const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
    return `Prazo de entrega: ${expirationPlus2BusinessDays.toLocaleDateString('pt-BR')} - ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  } else if (deliveryMethod === "collect") {
    let date;
    if (order && order.paid) {
        date = new Date(order.updated_at);
    } else {
        date = new Date();
    }
    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };
    const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
    return `Disponível para coleta apartir de: ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  }
}

export { calculateDeliveryPeriod };
