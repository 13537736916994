import { Box, Button, Image, Input, Text, Checkbox } from "@chakra-ui/react";
import Linkimage from '../../assets/images/boozen-link-img.webp';
import TikTokImg from '../../assets/images/tik-tok.png';

import { FaWhatsapp, FaInstagram  } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { theme } from "../../styles/theme";


export function LinkBoozen() {

  const handleCopyLink = (event, link) => {
    event.stopPropagation();
    navigator.clipboard.writeText(link);
    alert(`Link copiado para àrea de transferência: ${link}`);
  };

  const linkAttrs = [
    {
      link_url: "https://api.whatsapp.com/send?phone=55319713012280",
      link_innerHTML: <FaWhatsapp />,
    },
    {
      link_url: "https://tiktok.com/@boozenoficial",
      link_innerHTML: <img
        src={TikTokImg}
        alt="TikTok logo"
        style={{
          width: '25px',
          height: '25px',
          objectFit: 'cover',
          borderRadius: '50%',
          filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(268deg) brightness(103%) contrast(103%)',
        }}
      />,
    },
    {
      link_url: "https://instagram.com/boozenoficial?igshid=NzZlODBkYWE4Ng==",
      link_innerHTML: <FaInstagram />,
    },
  ];

  const boxAttrs = [
    {
      url: "https://www.boozen.com.br/",
      label: "Site",
      onClick: () => window.location.href = "https://www.boozen.com.br/"
    },
    {
      url: "/catalogo-24.pdf",
      label: "Catálogo 2024",
      onClick: () => window.location.href = "/catalogo-24.pdf"
    },
    {
      url: "https://api.whatsapp.com/send/?phone=5531971301228&text&type=phone_number&app_absent=0",
      label: "WhatsApp",
      onClick: () => window.location.href = "https://api.whatsapp.com/send/?phone=5531971301228&text&type=phone_number&app_absent=0"
    },
    {
      url: "https://www.tiktok.com/@boozenoficial",
      label: "TikTok",
      onClick: () => window.location.href = "https://www.tiktok.com/@boozenoficial"
    },
    {
      url: "https://www.youtube.com/@boozenoficial",
      label: "YouTube",
      onClick: () => window.location.href = "https://www.youtube.com/@boozenoficial"
    }
  ];


  const renderLinks = () => {
    return linkAttrs.map((link, index) => (
      <Link
        key={index}
        to={link.link_url}
        target="_blank"
      >
        <Button
          color="white"
          bg="transparent"
          border="none"
          fontSize={{ base: "25px", md: "30px" }}
          _hover={{
            transform: 'scale(1.1)',
          }}
        >
          {link.link_innerHTML}
        </Button>
      </Link>
    ));
  };

  const renderBoxes = () => {
    return boxAttrs.map((box, index) => (
      <Box
        key={index}
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={{ base: "10px", md: "15px" }}
        borderRadius="50px"
        marginBottom={{ base: "15px", md: "" }}
        background="#f8914a"
        width={{ base: "95%", md: "40%" }}
        sx={{
          position: 'relative',
          boxShadow: '6px 6px 0px 0px black',
          border: '2px solid black',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.3)',
            transform: 'translateY(2px) translateX(2px)',

          },
          cursor: 'pointer',
        }}
        onClick={box.onClick}
      >
        <Link
          to={box.url}
          style={{
            textDecoration: 'none',
            fontWeight: '700',
            fontSize: "20px",
            textAlign: "center",
            color: "white",
            width: "100%",
          }}
          target="_blank"
        >
          {box.label}
        </Link>
        <Button
          color="white"
          sx={{
            position: 'absolute',
            right: '10px',
            bg: 'transparent',
            border: 'none',
            borderRadius: '50%',
            width: "2.8rem",
            height: "2.8rem",
            '&:hover': {
              background: 'rgba(244, 244, 246, 0.7)',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            handleCopyLink(event, box.url);
          }}
        >
          <BsThreeDotsVertical />
        </Button>
      </Box>
    ))
  };



  return (
    <Box
      minHeight="100vh"
      sx={{
        background: 'linear-gradient(to bottom, rgb(104, 161, 66), rgb(15, 110, 28))',
      }}
      padding={{base: "20px 10px", md: "3rem 10rem"}}
      margin="0 auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="10px"
    >
      <Image src={Linkimage} alt={`image logo`} w="8rem" h="" objectFit="cover" borderRadius="50%" backgroundPosition="center" />
      <Text textAlign="center" color="white" fontWeight="800" fontSize="20px">Boozen</Text>
      <Text textAlign="center" color="white" fontWeight="600">Aqui é possível que você compre direto da fábrica sem ter que pagar a mais!</Text>
      <Box
        display="flex"
        justifyContent="center"
        gap={{base: "px", md: "10px"}}
        py="10px"
      >
        {renderLinks()}
      </Box>
      {renderBoxes()}
      <Text color="white" fontWeight="500">Email: contato@boozen.com.br</Text>
    </Box>
  )
}
