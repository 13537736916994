import { useState, useEffect } from "react";
import { createContext } from "react";
import { useAuth } from "../Hooks/useAuth";
import { api } from "../services/api";
import swal  from 'sweetalert';
import Swal from 'sweetalert2'

export const CartContext = createContext({});

export function CartContextProvider(props) {
  const { user } = useAuth();
  const [showCartPopUp, setShowCartPopUp] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [cartItemsCounter, setCartItemsCounter] = useState(0);

  useEffect(() => {
    if (user) {
      fetchUserCartCount();
    } else {
      const guestCartCount = parseInt(localStorage.getItem('cartItems') || '0', 10);
      setCartItemsCounter(guestCartCount);
    }
  }, [user]);

  async function fetchUserCartCount() {
    try {
      const response = await api.get(`/api/v1/cart_batches?user_id=${user.id}`);
      setCartItemsCounter(response.data.cart_batches.length);
    } catch (error) {
      console.error("Error fetching user cart count:", error);
    }
  }

  function handleSetSelectedProduct(e, product, batch, finalPrice, quantity) {
    e.preventDefault();
    if (user) {
      handleCreateCart(user, product, batch, finalPrice, quantity)
    } else {
      handleAddToCart(product, batch, finalPrice, quantity);
    }
  }

  function handleAddToCart(product, batch, finalPrice, quantity) {
    const cartData = localStorage.getItem('guestCart');
    let guestCart = [];
    try {
      if (cartData) {
        guestCart = JSON.parse(cartData) || [];
      }
    } catch (error) {
      console.error("Error parsing guestCart from localStorage:", error);
      guestCart = [];
    }
    guestCart.push({
      product_id: product.id,
      batch_id: batch.id,
      amount: Number(finalPrice),
      quantity: Number(quantity),
    });
    setShowCartPopUp(true);
    localStorage.setItem('guestCart', JSON.stringify(guestCart));
    const itemsCounter = guestCart.length;
    setCartItemsCounter(itemsCounter)
  }

  async function handleCreateCart(user, product, batch, finalPrice, quantity) {
    const cart = {
      user_id: user.id,
      batch_id: batch.id,
      amount: (finalPrice),
      quantity: quantity
    }
    const response = await api.post("/api/v1/cart_batches", cart);
    if (response.status === 200) {
      setShowCartPopUp(true);
      fetchUserCartCount()
      if (window && window.gtag) {
        window.gtag('event', 'add_to_cart', {
          currency: "BRL",
          value: finalPrice,
          'items': [{
            'item_name': product.name,
            'item_id': product.id,
            'price': finalPrice,
            'item_brand': product.brand,
            'quantity': quantity
          }]
        });
      }

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq('trackCustom', 'handleCreateCart');
        }
      }
    }
  }


  function decrementCartCounter() {
    setCartItemsCounter(prevCount => Math.max(prevCount - 1, 0));
    if (!user) {
      const currentCounter = parseInt(localStorage.getItem('cartItems') || '0', 10);
      localStorage.setItem('cartItems', Math.max(currentCounter - 1, 0).toString());
    }
  }

  return (
    <CartContext.Provider
      value={{cartItemsCounter, showCartPopUp, setShowCartPopUp, selectedProduct, handleSetSelectedProduct, decrementCartCounter, handleCreateCart}}
    >
      {props.children}
    </CartContext.Provider>
  );
}
