import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { Footer } from './components/Footer';


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

if (typeof globalThis === 'undefined') {
  var globalThis = Function('return this')();
}

root.render(
  <StrictMode>
    <ColorModeScript />
    <App />
  </StrictMode>
);
